@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@500&display=swap"); */
html,
body {
  overflow-x: hidden;
}

.product-cards {
  margin: auto;
  border: none;
  transition: 0.7s;
  padding: 1.2em;

  /* box-shadow: 10px 4px 8px 10px rgba(104, 97, 97, 0.2); */
}

.product-cards:hover {
  /* transform: translateY(-30px); */
  box-shadow: 10px 4px 8px 10px rgba(104, 97, 97, 0.2);
}
.d-block {
  max-height: 90vh;
  /* object-fit: cover; */
  position: relative;
}
.carousel-caption {
  color: #000000;
}

.carousel-item {
  background: linear-gradient(blue) !important;
}
.carousal-title {
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
}

.carousel-caption {
  font-family: "Nunito Sans", sans-serif;

  /* color: gray; */
}
/* * {
  overflow-x: hidden;
} */

.img-why {
  width: 100%;
}

/* Footer start here */
* {
  font-family: Open Sans;
}

section {
  width: 100%;
  display: inline-block;
  background: #333;
  height: 50vh;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}

.footer-distributed {
  background: #666;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding-top: 55px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;

  /* padding: 20px 50px; */
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 40%;
}

/* The company logo */

.footer-distributed h3 {
  color: #ffffff;
  font: normal 36px "Open Sans", cursive;
  margin: 0;
}

.footer-distributed h3 span {
  color: lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: #222;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.nav-link {
  /* font-family: "Alegreya", serif; */
  font-size: 1.12em;
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: lightseagreen;
  text-decoration: none;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 20%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;

  /* font-size: 20px; */
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center {
    text-align: justify;
  }
  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}

/* Footer end */

.card-text {
  text-align: center;
}

.card-img {
  max-height: 5em;
  max-width: 11em;
  object-fit: scale-down;

  height: auto;
  margin: auto;
}

.product-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  margin: 1em;
}

.feature-content {
  display: inline-block;
  /* margin-top: 0; */
  /* margin-bottom: 0; */
  /* padding-right: 20px !important; */
  /* color: aqua; */
}

.feature {
  display: inline-block;
  margin-left: 2em;
  /* margin-top: 1em; */
  margin-bottom: 1em;
}
.why-feature {
  display: inline-flex;
}
.dot {
  height: 60px;
  width: 60px;
  text-align: center;
  background-color: #f6892d;
  border-radius: 100%;
  display: inline-block;
}
.feature-icon {
  font-size: 1.5em !important;
  padding: 15px;
}

.support-bg {
  background-image: linear-gradient(
      to bottom,
      rgba(25, 125, 155, 0.73),
      rgba(25, 125, 155, 0.73)
    ),
    url(/static/media/support.9ab6f865.jpg);

  background-position: center;
  background-size: scale-down;

  color: #ffffff;

  height: 17em;
}

.support-content {
  padding-top: 6em;
}

.support-content p {
  color: darkgray;
}

.hg {
  font-size: 2.5em;
}

.support-phone {
}
.contact-card {
  width: 18em;

  margin: auto;
  margin-top: 1em;
  height: 12em;
}

.fa-whatsapp {
  color: green;
}

.fa-envelope-o {
  color: #d44638;
}
.fa-phone {
  color: #009688;
}
.copyright {
  margin-top: 2em;
}

.my-float {
  margin-top: 7px;
  font-size: 40px;
  color: white;
  /* font-size: 50; */
}
.fa-whatsapp {
  /* align-items: center; */
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}
.float:hover {
  color: #000;
}
.customer-logo {
  /* max-width: 100%; */
  align-items: center;
  vertical-align: middle;
  margin: 0.5em;
}

/* support card */

.cards {
  width: auto;
  margin: 1em;
  /* height: 5em; */
  padding: 1em;
  /* line-height: 5em; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.cards .fa {
  margin: 1em;
  font-size: 1.6em;
}
/* .col-3 .d-block {
  width: 12em !important;
} */

.logo-home {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  margin: 1em;
}
.certificate {
  max-width: 30em;
  height: auto;
  margin-bottom: 2em;
}
.mobile-logo {
  display: none;
}

@media only screen and (max-width: 600px) {
  .product-title {
    font-size: xx-large;
  }
  .logo-home {
    font-size: xx-large;
  }
  .certificate {
    max-width: 100%;
    height: auto;
    margin-bottom: 2em;
  }
  .mobile-logo {
    display: block;
  }
  .Big-screen-logo {
    display: none;
  }
}
.mail-link {
  color: white !important;
}

